<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <validation-observer ref="simpleRules">
    <Overplay v-if="isShow" />
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <BasicInformation
              id="BI"
              v-model="model"
            />
          </Fieldset>
          <!-- <Fieldset id="salesinformation" title="Attributes">
            <Variation v-model="model" />
          </Fieldset> -->
          <!-- <Fieldset id="salesinformation" :title="$t('Sales Information')">
            <SalesInformation id="SI" v-model="model" />
          </Fieldset> -->
          <!-- <Fieldset id="mediamanagement" :title="$t('Media Management')">
            <MediaManagement id="MM" v-model="model" />
          </Fieldset> -->
          <Fieldset id="shippingdetail" title="Shipping Detail">
            <ShippingDetail id="SD" v-model="model" />
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group label="Status:" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <hr>
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  Create on Lazada
                </b-button>
              </div>
            </Fieldset>
            <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.images"
                @previewFiles="previewFiles"
              />
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unreachable */

import VueScrollactive from 'vue-scrollactive'
import {
  // BLink,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Vue from 'vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Variation from './sync-product-components/components/Variation.vue'
import BasicInformation from './sync-product-components/BasicInformation.vue'
// import SalesInformation from './sync-product-components/SalesInformation.vue'
// import MediaManagement from './sync-product-components/MediaManagement.vue'
import ShippingDetail from './sync-product-components/ShippingDetail.vue'

Vue.use(VueScrollactive)

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BasicInformation,
    // SalesInformation,
    // MediaManagement,
    BButton,
    ValidationObserver,
    BFormCheckbox,
    BFormGroup,
    ShippingDetail,
    // Variation,
  },
  mixins: [general],
  data() {
    return {
      isShow: false,
      model: {
        SellerSku: null,
        site_id: null,
        is_active: true,
        category_id: null,
        name: null,
        short_content: null,
        brand: 'Remark',
        attribute: {
          Sku: {
            SellerSku: null,
            size: null,
            quantity: null,
            price: null,
            package_length: null,
            package_height: null,
            package_weight: null,
            package_width: null,
            Images: [
              { Image: null },
            ],
          },
        },
      },
    }
  },
  async mounted() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/product/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.model = {
              ...this.model,
              images: res.data.data.avatar,
              name: res.data.data.name,
              item_sku: res.data.data.sku,
              description: res.data.data.content,
              price: res.data.data.price,
              length: res.data.data.length,
              weight: res.data.data.weight,
              width: res.data.data.width,
              height: res.data.data.height,
            }
          } else {
            this.model.isShow = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.model.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.isShow = true
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            // site_id: null,
            // is_active: true,
            // category_id: null,
            // name: null,
            // short_content: null,
            // brand: null,
            // attribute: [
            //   {
            //     Sku: {
            //       SellerSku: null,
            //       size: null,
            //       quantity: null,
            //       price: null,
            //       package_length: null,
            //       package_height: null,
            //       package_weight: null,
            //       package_width: null,
            //       Images: [
            //         { Image: null },
            //       ],
            //     },
            //   },
            // ],
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              category_id: 1780,
              name: 'Máy nước nóng Ariston',
              short_content: '<p>test<p>',
              brand: 'Remark',
              attribute: JSON.stringify({
                Sku: {
                  SellerSku: 5943,
                  size: '40',
                  quantity: 15,
                  price: 500000,
                  package_length: 1,
                  package_height: 4,
                  package_weight: 3,
                  package_width: 2,
                  Images: [{ Image: 'https://beta-fileapi.busodevelopers.com//upload/132/2021/10/products_media/desktop/104029c8c93da0729c7f56517678dadc.png' }],
                },
              }),
              price: 50000,
            }
            // const params = {
            //   site_id: JSON.parse(localStorage.getItem('siteID')).id,
            //   category_id: this.model.category_id,
            //   name: this.model.name,
            //   short_content: this.model.description,
            //   price: this.model.price,
            //   brand: this.model.brand,
            //   attribute: JSON.stringify(
            //     {
            //       Sku: {
            //         SellerSku: this.model.item_sku,
            //         size: this.model.size,
            //         quantity: Number(this.model.quantity),
            //         price: Number(this.model.price),
            //         package_length: Number(this.model.length),
            //         package_height: Number(this.model.height),
            //         package_weight: Number(this.model.weight),
            //         package_width: Number(this.model.width),
            //         Images: [
            //           { Image: this.model.images },
            //         ],
            //       },
            //     },
            //   ),
            // }
            await this.handleCreate(params)
            this.isShow = false
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
            this.isShow = false
          }
        } else {
          this.isShow = false
        }
      })
    },
    async handleCreate(params) {
      this.isShow = true
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/lazada/add_item`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Product on Lazada success',
            },
          })
          this.$router.push('/channel/lazada')
          this.isShow = false
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
          this.isShow = false
        }
      }
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.images = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss" scoped>
.right-fixed-menu {
  width: 100%;
  max-width: 350px;
  right: 30px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    margin: 8px 0;
    position: relative;
    color: var(--secondary);
    &:before {
      content: '';
      height: 100%;
      width: 1.8px;
      background: var(--secondary);
      position: absolute;
      left: 0;
      top: 0;
    }
    &.active {
      color: var(--primary);
      font-weight: bold;
      &:before {
        width: 2px;
        background: var(--primary);
      }
    }
  }
}
</style>
